<template>
  <div
    :class="rootClass"
    class="vs-sidebar--item"
  >
    <a
      v-if="isModal"
      @click="handleClick"
    >
      <span
        v-if="!featherIcon"
        class="material-icons iconsmaterial"
        :icon-pack="iconPack"
      >
        {{ icon }}
      </span>

      <feather-icon
        v-else
        :icon="icon"
        :class="{'w-3 h-3': iconSmall}"
      />

      <slot />
    </a>

    <router-link
      v-else-if="to"
      :to="to"
      :class="[{'router-link-active': activeLink}]"
      :target="target"
      exact
    >
      <span
        v-if="!featherIcon"
        class="material-icons iconsmaterial"
        :icon-pack="iconPack"
      >
        {{ icon }}
      </span>

      <feather-icon
        v-else
        :icon="icon"
        :class="{'w-3 h-3': iconSmall}"
      />
            
      <slot />
    </router-link>
    
    <a
      v-else
      :target="target"
      :href="href"
    >
      <span
        v-if="!featherIcon"
        class="material-icons iconsmaterial"
        :icon-pack="iconPack"
      >
        {{ icon }}
      </span>

      <feather-icon
        v-else
        :icon="icon"
        :class="{'w-3 h-3': iconSmall}"
      />

      <slot />
    </a>
  </div>
</template>

<script>
export default {
  name: "VxSidebarItem",

  props: {
    icon: {
      default: "",
      type: String,
    },

    iconSmall: {
      default: false,
      type: Boolean,
    },

    iconPack: {
      default: "material-icons",
      type: String,
    },

    href: {
      default: "#",
      type: String,
    },

    to: {
      default: null,
      type: String,
    },

    slug: {
      default: null,
      type: String,
    },

    index: {
      default: null,
      type: [String, Number],
    },

    featherIcon: {
      default: false,
      type: Boolean,
    },

    target: {
      default: "_self",
      type: String,
    },

    isDisabled: {
      default: false,
      type: Boolean,
    },

    isModal: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      activeLink: false,
    }
  },

  computed: {
    rootClass () {
      return [
        this.activeLinkClass,
        this.disabledLinkClass,
      ]
    },

    activeLinkClass () {
      return this.activeLink
        ? "vs-sidebar-item-active"
        : ""
    },

    disabledLinkClass () {
      return this.isDisabled
        ? "disabled-item pointer-events-none"
        : ""
    },
  },

  updated() {
    this.checkIsActive()
  },

  methods: {
    checkIsActive() {
      if (this.to) {
        this.activeLink = (                 
          (this.to == this.$router.path && this.to) || (this.$route.meta.parent == this.slug)
        )
      }
    },

    handleClick(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()

      this.$emit("click", event)
    },
  },

  watch: {
    "$route"() {
      this.checkIsActive()
    },
  },
}
</script>

<style lang="scss" scoped>
.vs-sidebar--item {
  a {
    cursor: pointer !important;
  }

  .iconsmaterial{
    font-size: 1.8rem !important;
    margin-right: 6%;
  }
}
</style>
