var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main",class:[_vm.navbarClasses, _vm.footerClasses, { 'app-page': _vm.isAppPage }]},[_c('vx-sidebar',{attrs:{"sidebarItems":_vm.sidebarItems,"logo":require('@/assets/images/logo/logo.png'),"title":"Vuesax","parent":".layout--main"},on:{"showModal":_vm.showModal}}),_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),_c('div',{staticClass:"content-wrapper"},[_c('the-navbar',{key:_vm.toRerender,class:[
        { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
        { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark },
      ],attrs:{"navbarColor":_vm.navbarColor}}),_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content",class:{ 'mt-0': _vm.navbarType == 'hidden' }},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap items-center mb-6"},[_c('div',{staticClass:"content-area__heading",class:{
                'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light':
                  _vm.$route.meta.breadcrumb,
              }},[_c('h2',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.routeTitle))])]),(_vm.$route.meta.breadcrumb)?_c('vx-breadcrumb',{staticClass:"ml-4 md:block hidden",attrs:{"route":_vm.$route}}):_vm._e(),_c('vs-dropdown',{staticClass:"ml-auto md:block hidden cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('vs-button',{attrs:{"radius":"","icon":"icon-settings","icon-pack":"feather"}}),_c('vs-dropdown-menu',{staticClass:"w-32"},[_c('vs-dropdown-item',[_c('div',{staticClass:"flex items-center",on:{"click":function($event){return _vm.$router.push('/pages/profile')}}},[_c('feather-icon',{staticClass:"inline-block mr-2",attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',[_vm._v("Perfil")])],1)])],1)],1)],1):_vm._e()]),_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle,"showModal":_vm.showModal}})],1)],1)],1)])],1),_c('the-footer')],1),_c('modal-wait-24h',{attrs:{"id":"modal-wait-24h"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }