<template>
  <div class="parentx">
    <vs-sidebar
      v-hammer:swipe.left="onSwipeLeft"
      ref="mainSidebar"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      default-index="-1"
      class="sidebarx main-menu-sidebar items-no-padding"
      v-model="isSidebarActive"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
    >
      <div class="d-flex flex-column h-100" @mouseenter="sidebarMouseEntered" @mouseleave="sidebarMouseLeave">
        <div
          class="header-sidebar row flex items-end justify-end"
          slot="header"
        >
          <vs-row vs-type="flex" vs-justify="center">
            <div class="logo flex items-center">
              <img
                v-if="logo"
                :src="sidebarLogo.startsWith('data:image')
                  ? sidebarLogo
                  : require(`@/assets/images/logo/${sidebarLogo}`)"
                alt="logo"
                width="80%"
              />
            </div>
          </vs-row>
          <div>
            <feather-icon
              v-if="showCloseButton"
              icon="XIcon"
              class="m-0 cursor-pointer"
              color="orange"
              :svgClasses="['stoke-current text-warning']"
              @click="$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)"
            />
          </div>
        </div>

        <div class="shadow-bottom" v-show="showShadowBottom"/>

        <VuePerfectScrollbar
          ref="mainSidebarPs"
          class="scroll-area--main-sidebar pt-2"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
        >
          <template v-for="(sidebarItem, index) in sidebarItems">
            <!-- GROUP ITEM HEADER -->
            <span
              v-if="sidebarItem.header && !sidebarItemsMin"
              :key="`header-${index}`"
              class="navigation-header truncate"
            >
              {{ sidebarItem.header }}
            </span>
            
            <template v-else-if="sidebarItem.isPresented">
              <!-- IF HAVE SUBMENU / DROPDOWN -->
              <template v-if="sidebarItem.items">
                <vx-sidebar-group ref="sidebarGrp" :key="`group-${index}`" :openHover="openGroupHover"
                  :group="sidebarItem" :groupIndex="index" :open="isGroupActive(sidebarItem)"></vx-sidebar-group>
              </template>

              <!-- IF IT'S SINGLE ITEM -->
              <template v-else>
                <vx-sidebar-item
                  v-if="sidebarItem.isModal"
                  ref="sidebarLink"
                  :is-modal="sidebarItem.isModal"
                  @click="popupCancelamento = true"
                  :key="`sidebarItem-modal-${index}`"
                  :icon="sidebarItem.icon"
                >
                  <span v-show="!sidebarItemsMin" class="truncate" @click="popupCancelamento = true">
                    {{ sidebarItem.name }}
                  </span>
                  <vs-chip class="ml-auto" :color="sidebarItem.tagColor"
                    v-if="sidebarItem.tag && (isMouseEnter || !reduce)">{{ sidebarItem.tag }}</vs-chip>
                </vx-sidebar-item>

                <vx-sidebar-item v-else ref="sidebarLink" :key="`sidebarItem-${index}`"
                  :href="sidebarItem.isExternal ? sidebarItem.url : ''"
                  :to="!sidebarItem.isExternal ? sidebarItem.url : ''"
                  :index="index"
                  :icon="sidebarItem.icon"
                  :target="sidebarItem.target"
                  :isDisabled="sidebarItem.isDisabled"
                  :slug="sidebarItem.slug">
                  <span v-show="!sidebarItemsMin" class="truncate">
                    {{ sidebarItem.name }}
                  </span>
                  <vs-chip class="ml-auto" :color="sidebarItem.tagColor"
                    v-if="sidebarItem.tag && (isMouseEnter || !reduce)">{{ sidebarItem.tag }}</vs-chip>
                </vx-sidebar-item>
              </template>
            </template>
          </template>
        </VuePerfectScrollbar>

        <div class="row d-flex justify-center items-center p-3" style="height: 140px;">
          <img src="@/assets/images/dasa/powered-by-filoo.png" alt="powered by filoo" height="40px" width="140px">
        </div>
      </div>
    </vs-sidebar>

    <div
      v-hammer:swipe.right="onSwipeRightSidebarSwipeArea"
      v-if="!isSidebarActive"
      class="sidebar-swipe-area"
      id="sidebar-swipe-area"
    />

    <b-modal
      title="Antes de começarmos o atendimento..."
      v-model="popupCancelamento"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
    >
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="start" vs-w="12">
        <vs-row vs-type="flex" vs-justify="center" id="sintomas">
          <vs-col
            vs-type="flex-start"
            vs-justify="center"
            vs-align="center"
            vs-w="8"
          >
            <p>
              <strong>Comece nos contando o que você está sentindo.</strong>
            </p>
            <textarea
              type="text"
              style="resize: none; height: auto !important"
              rows="10"
              cols="200"
              placeholder="Digite aqui o que você está sentindo."
              v-model="symptoms"
              class="required-field w-full"
            >
              Digite aqui o que você está sentindo.
            </textarea>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-row id="bntFooter">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="border" v-on:click="sendScheduling()">
            Enviar
          </vs-button>
        </vs-col>
      </vs-row>
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"

import ModalComponent from "@/components/modal-maps/modal.component.vue"
import VxSidebarGroup from "./VxSidebarGroup.vue"
import VxSidebarItem from "./VxSidebarItem.vue"

export default {
  name: "VxSidebar",
  components: {
    VxSidebarGroup,
    VxSidebarItem,
    VuePerfectScrollbar,
    ModalComponent,
  },
  props: {
    sidebarItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    logo: {
      type: String,
    },
    parent: {
      type: String,
    },
    openGroupHover: {
      type: Boolean,
      default: false,
    },
    reduceNotRebound: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    clickNotClose: false, // disable close sidebar on outside click
    reduce: false, // determines if sidebar is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    isMouseEnter: false,
    settings: { // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true,
    },
    popupCancelamento: false,
    symptoms: "",
    windowWidth: window.innerWidth, //width of windows
    showShadowBottom: false,
  }),
  computed: {
    sidebarLogo() {
      return this.$session.get("projectPhoto")
        ? `data:image/png;base64,${this.$session.get("projectPhoto")}`
        : this.$store.state.sidebarLogo
    },
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", val)
      },
    },
    reduceSidebar() {
      return Boolean(this.reduce && this.reduceButton)
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", val)
      },
    },
    sidebarItemsMin() {
      return this.$store.state.sidebarItemsMin
    },
    isGroupActive() {
      return (sidebarItem) => {
        const path = this.$route.fullPath
        let open = false

        const func = function (sidebarItem) {
          if (sidebarItem.items) {
            sidebarItem.items.forEach((item) => {
              if (path == item.url) {
                open = true
              } else if (item.items) {
                func(item)
              }
            })
          }
        }

        func(sidebarItem)
        return open
      }
    },
  },
  mounted() {
    this.reduce = false
    this.toggleReduce(false)
    this.sidebarMouseLeave()
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize)
    })
    this.setSidebarWidth()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize)
  },
  methods: {
    sendScheduling() {
      this.$vs.loading()
      this.popupCancelamento = false

      this.axios.post(
        `${this.$store.state.filooServer}topmed/scheduling`,
        { sintomas: this.symptoms }
      )
        .then((res) => {
          if (res.status === 200) {
            if (!res.data || !res.data.EmailDirecionado) {
              this.$swal({
                title: "Atenção!",
                text: "Agendamento não enviado!   Usuário já consta na fila de atendimento, verifique seu email para mais informações.",
                icon: "warning",
              })
            } else {
              this.$swal({
                title: "Sucesso!",
                text: "Agendamento enviado!  Você foi colocado na fila.",
                icon: "success",
              });
              window.open(res.data.LinkDirecionamento, "_blank").focus()
            }
          } else {
            this.$emit("showModal", "modal-wait-24h")
          }
        })
        .catch((error) => {
          console.error(error)
          this.$emit("showModal", "modal-wait-24h")
        })
        .finally(() => {
          this.$vs.loading.close()
        })
      this.symptoms = ""
    },
    sidebarMouseEntered() {
      if (this.reduce) this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false)
      this.isMouseEnter = true
    },
    sidebarMouseLeave() {
      if (this.reduce) {
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true)
      }
      this.isMouseEnter = false
    },
    toggleReduce(val) {
      this.reduceButton = val
      this.setSidebarWidth()
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.setSidebarWidth()
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit(
          "UPDATE_WINDOW_BREAKPOINT",
          this.windowWidth < 992 ? "md" : "lg"
        )
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)

        if (this.reduceButton) {
          this.reduce = false
        }

        this.reduceButton = false
        this.showCloseButton = true
        this.clickNotClose = false
        this.$store.dispatch("updateSidebarWidth", "no-sidebar")
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false)
      } else {
        this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "xl")
        
        this.reduce = this.reduceButton
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true)
        
        this.$store.commit(
          "UPDATE_SIDEBAR_ITEMS_MIN",
          this.reduceButton && !this.isMouseEnter
        )

        this.clickNotClose = true
        this.showCloseButton = false
        
        this.$store.dispatch(
          "updateSidebarWidth",
          this.reduceSidebar ? "reduced" : "default"
        )
      }
    },
    psSectionScroll() {
      this.showShadowBottom = this.$refs.mainSidebarPs.$el.scrollTop > 0
    },
    onSwipeLeft() {
      if (this.isSidebarActive && this.showCloseButton) {
        this.isSidebarActive = false
      }
    },
    onSwipeRightSidebarSwipeArea() {
      if (!this.isSidebarActive && this.showCloseButton) {
        this.isSidebarActive = true
      }
    },
  },
  watch: {
    reduce(val) {
      this.$store.dispatch(
        "updateSidebarWidth",
        val ? "reduced" : "default"
      )

      setTimeout(function () {
        window.dispatchEvent(new Event("resize"))
      }, 100)
    },
    reduceButton() {
      this.setSidebarWidth()
    },
    "$route"() {
      if (this.isSidebarActive && this.showCloseButton)
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxSidebar.scss";
</style>
