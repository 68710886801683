<template src="./Main.html" />

<script>
import { computed, defineComponent } from "vue"
import BackToTop from "vue-backtotop"

import { useStore } from "@/store/store.js"
import ModalWait24h from "@/components/ModalWait24h.vue"
import TheFooter from "@/components/TheFooter.vue"
import TheNavbar from "@/components/TheNavbar.vue"
import VxSidebar from "@/components/VxSidebar/VxSidebar.vue"
import themeConfig from "@/../themeConfig.js"
import mixins from "@/mixins/mixins.js"

export default defineComponent({
  name: "MainLayout",
  mixins: [mixins],
  components: {
    BackToTop,
    ModalWait24h,
    TheNavbar,
    TheFooter,
    VxSidebar,
  },
  data() {
    return {
      navbarType: themeConfig.navbarType || "floating",
      navbarColor: themeConfig.navbarColor || "#fff",
      footerType: themeConfig.footerType || "static",
      routerTransition: themeConfig.routerTransition || "none",
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      disableCustomizer: themeConfig.disableCustomizer,
      windowWidth: window.innerWidth, //width of windows
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableThemeTour: themeConfig.disableThemeTour,
      menus: [],
      planInfo: {},
      toRerender: 0,
    }
  },
  computed: {
    isAppPage() {
      return !!this.$route.path.includes("/apps/")
    },
    contentAreaClass() {
      return (this.sidebarWidth === "default" || this.sidebarWidth === "reduced")
        ? `content-area-${this.sidebarWidth}`
        : "content-area-full"
    },
    navbarClasses() {
      return `navbar-${this.navbarType}`
    },
    footerClasses() {
      return `footer-${this.footerType}`
    },
  },
  setup () {
    const store = useStore()

    return {
      customerBalance: computed(() => store.getters.customerBalance ),
      isThemeDark: computed(() => store.getters.isThemeDark ),
      sidebarWidth: computed(() => store.getters.sidebarWidth ),
      sidebarItems: computed(() => store.getters.sidebarItems),
      bodyOverlay: computed(() => store.getters.bodyOverlay),
    }
  },
  async created() {
    await this.getMe()
    await this.setSidebarWidth()

    const color = this.navbarColor.trim() === "#fff" && this.isThemeDark
      ? "#10163a"
      : this.navbarColor

    await this.updateNavbarColor(color)
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      this.isNavbarDark = val.trim() !== "#fff"
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", this.windowWidth >= 1200)

      if (this.windowWidth < 1200) {
        this.$store.dispatch("updateSidebarWidth", "no-sidebar")
        this.disableThemeTour = true
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val
    },
    goToWallet() {
      this.$nextTick(() => this.$router.push("/wallet"))
    },
    showModal (name) {
      this.$bvModal.show(name)
    },
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      if (this.navbarColor == "#fff" && val) {
        this.updateNavbarColor("#10163a")
      } else {
        this.updateNavbarColor("#fff")
      }
    },
    customerBalance: function () {
      if (this.toRerender === 0) this.toRerender += 1
      else this.toRerender -= 1
    },
  },
})
</script>

<style lang="scss" src="./Main.scss" scoped />
