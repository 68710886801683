<template>
  <b-modal
    :id="id"
    title="Erro ao acessar a consulta"
    centered
    hide-footer
    no-close-on-backdrop
    hide-header-close
    no-close-on-esc
  >
    <h6>O serviço de Telemedicina será ativado em até 24 horas.</h6>

    <h6>
      Caso o problema persista entre em contato com a equipe de atendimento
      para informar o ocorrido através do e-mail:
      <a href="mailto:contato@filoo.com.br" target="_blank">
        contato@filoo.com.br
      </a>
      ou pelo whatsapp
      <a href="https://wa.me/55011996507610" target="_blank">
        (011) 99650-7610
      </a>.
    </h6>

    <vs-button
      style="float: right; text-align: justify;"
      type="filled"
      @click="closeModal"
    >
      Ok
    </vs-button>
  </b-modal>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "ModalWait24h",
  
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  methods: {
    closeModal() {
      const url = "/plan-upgrade"
      this.$bvModal.hide(this.id)

      if (window.location.href.indexOf(url) <= 0) {
        this.$router.push(url)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.modal-body{
  padding-top: 5%;
  padding-bottom: 3%;
  text-align: center;
}
</style>