<template>
  <div
    :class="[{'vs-sidebar-group-open' : openItems}, {'vs-sidebar-group-active': open}, {'disabled-item pointer-events-none': group.isDisabled}]"
    class="vs-sidebar-group"
    @mouseover="mouseover"
    @mouseout="mouseout"
  >
    <div @click="clickGroup" class="group-header w-full">
      <span class="flex items-center w-full">
        <span class="material-icons iconsmaterial"   v-if="group.icon || (this.groupIndex > Math.floor(this.groupIndex))" >{{ group.icon || 'error' }}</span>
        <span v-show="!sidebarItemsMin" class="truncate mr-3 select-none">{{ group.name }}</span>
        <vs-chip class="ml-auto mr-4" :color="group.tagColor" v-if="group.tag && !sidebarItemsMin">{{ group.tag }}</vs-chip>
      </span>

      <feather-icon icon="ChevronRightIcon" svg-classes="w-4 h-4" :class="[{'rotate90' : openItems}, 'feather-grp-header-arrow']" v-show="!sidebarItemsMin" />

      <span class="vs-sidebar--tooltip">{{ group.name }}</span>
    </div>

    <ul ref="items" :style="styleItems" class="vs-sidebar-group-items">
      <template v-for="(groupItem, index) in group.items">
        <li v-if="groupItem.isPresented" :key="index">
          <vx-sidebar-item
            v-if="groupItem.isModal"
            icon-small
            @click="modalClick(groupItem.slug)"
            :index="`${groupIndex}.${index}`"
            :to="groupItem.url"
            :icon="itemIcon(`${groupIndex}.${index}`)"
            :is-modal="groupItem.isModal"
            :slug="groupItem.slug"
            :target="groupItem.target"
          >
            <span class="truncate" @click="modalClick(groupItem.slug)">{{ groupItem.name }}</span>
            <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
          </vx-sidebar-item>
  
          <vx-sidebar-item v-else
            icon-small
            :index="`${groupIndex}.${index}`"
            :href="groupItem.isExternal ? groupItem.url : ''"
            :to="!groupItem.isExternal ? groupItem.url : ''"
            :icon="itemIcon(`${groupIndex}.${index}`)"
            :slug="groupItem.slug"
            :target="groupItem.target"
          >
            <span class="truncate">{{ groupItem.name }}</span>
            <vs-chip class="ml-auto" :color="groupItem.tagColor" v-if="groupItem.tag">{{ groupItem.tag }}</vs-chip>
          </vx-sidebar-item>
        </li>
      </template>
    </ul>

    <b-modal title="Pronto Atendimento Virtual" v-model="popupNav" hide-footer centered no-close-on-backdrop size="lg">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="start" vs-w="12">
        <vs-row vs-type="flex" vs-justify="center" id="sintomas" >
          <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="8">
            <p>O Serviço de pronto atendimento virtual é realizado através da plataforma NAV, ao clicar abaixo você será automaticamente redirecionado.</p>
            <p><strong>Atenção: Se você ainda não possuir cadastro, precisará realizá-lo para ter acesso ao serviço</strong></p>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-row id="bntFooter">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="border" color="primary" v-on:click="openLink()">Confirmar</vs-button>
        </vs-col>
      </vs-row>
    </b-modal>

    <b-modal title="Agendamento de Telemedicina" v-model="popupNavTelemed" hide-footer centered no-close-on-backdrop size="lg">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="start" vs-w="12">
        <vs-row vs-type="flex" vs-justify="center" id="sintomas" >
          <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="8">
            <p>O Serviço de telemedicina é realizado através da plataforma NAV, ao clicar abaixo você será automaticamente redirecionado.</p>
            <p><strong>Atenção: Se você ainda não possuir cadastro, precisará realizá-lo para ter acesso ao serviço</strong></p>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-row id="bntFooter">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="border" color="primary" v-on:click="openLinkTelemed()">Confirmar</vs-button>
        </vs-col>
      </vs-row>
    </b-modal>

    <b-modal title="Cirurgias" v-model="popupSurgerys" hide-footer centered no-close-on-backdrop size="lg">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="start" vs-w="12">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="8">
            <p>
              Com sua assinatura do Cartão Dasa + Saúde PLANO “PLUS” você tem acesso a preços especiais para realizar cirurgias em nossa Rede Hospitalar.<br>
              <a href="Instrucoes-sobre-Pacotes-Cirurgicos.pdf" target="_blank">Clique aqui</a> e confira os procedimentos disponíveis em nossos Hospitais participantes.
            </p>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-row id="bntFooter">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button type="border" color="primary" v-on:click="popupSurgerys=false">Entendido</vs-button>
        </vs-col>
      </vs-row>
    </b-modal>
  </div>
</template>

<script>
import VxSidebarItem from "./VxSidebarItem.vue"

export default {
  name: "vx-sidebar-group",
  components: {
    VxSidebarItem,
  },
  props: {
    openHover: {
      default: false,
      type: Boolean,
    },
    open: {
      default: false,
      type: Boolean,
    },
    group: {
      type: Object,
    },
    groupIndex: {
      type: Number,
    },
    iconPack: {
      default: "material-icons",
      type: String,
    },
  },
  data: () => ({
    maxHeight: "0px",
    openItems: false,
    popupNav: false,
    popupNavTelemed: false,
    popupSurgerys: false,
  }),
  computed: {
    sidebarItemsMin() {
      return this.$store.state.sidebarItemsMin;
    },
    styleItems() {
      return {
        maxHeight: this.maxHeight,
      }
    },
    itemIcon() {
      return (index) => {
        if (!((index.match(/\./g) || []).length > 1)) return "arrow_right"
      }
    },
    isGroupActive() {
      return (sidebarItem) => {

        const path = this.$route.fullPath
        let open = false;
        const func = function(sidebarItem) {
          if (sidebarItem.items) {
            sidebarItem.items.forEach((item) => {
              if (path == item.url) {
                open = true
              } else if (item.items) {
                func(item)
              }
            })
          }
        }

        func(sidebarItem)
        return open
      }
    },
  },
  mounted() {
    this.openItems = this.open

    if (this.open) {
      this.maxHeight = "none"
    }
  },
  methods: {
    modalClick(slug) {
      if (slug === "MyServices.EmergencyCareDasa") {
        this.popupNav = true;
      } else if (slug === "MyServices.EmergencyCareDasaTelemed") {
        this.popupNavTelemed = true;
      }else if(slug === "MyServices.Surgerys"){
        this.popupSurgerys = true;
      }
    },
    openLink(){
      var win = window.open("https://nav.dasa.com.br/entrar?redirectTo=/plataforma/consultas/coa/?origin=DASA_EMPRESAS", "_blank");
      win.focus();
      this.popupNav = false
    },
    openLinkTelemed(){
      var win = window.open("https://nav.dasa.com.br/entrar?redirectTo=/plataforma/consultas/agendamento?origin=DASA_EMPRESAS", "_blank");
      win.focus();
      this.popupNavTelemed = false
    },
    clickGroup() {
      if (!this.openHover) {
        const thisScrollHeight = this.$refs.items.scrollHeight
        if (this.maxHeight == "0px") {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = "none"
          }, 300)
        } else {
          this.maxHeight = `${thisScrollHeight}px`
          setTimeout(() => {
            this.maxHeight = `${0}px`
          }, 50)
        }

        this.$parent.$children.map((child) => {
          if (child.isGroupActive) {

            if (child !== this && (!child.open) && child.maxHeight != "0px") {
              setTimeout(() => {
                child.maxHeight = `${0}px`
              }, 50)
            }
          }
        })
      }
    },
    mouseover() {
      if (this.openHover) {
        const scrollHeight = this.$refs.items.scrollHeight
        this.maxHeight = `${scrollHeight}px`
      }
    },
    mouseout() {
      if (this.openHover) {
        const scrollHeight = 0
        this.maxHeight = `${scrollHeight}px`
      }
    },
  },
  watch: {
    // OPEN & CLOSES DROPDOWN ON ROUTE CHANGE
    "$route"() {
      if(this.sidebarItemsMin) return
      const scrollHeight = this.scrollHeight
      if (this.openItems && !this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = `${0}px`
        }, 50)
      } else if (this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "none"
        }, 300)
      }
    },
    maxHeight() {
      this.openItems = this.maxHeight != "0px"
    },
    // OPEN AND CLOSES DROPDOWN MENU ON SIDEBAR COLLAPSE AND DEFAULT VIEW
    "$store.state.sidebarItemsMin"(val) {
      const scrollHeight = this.$refs.items.scrollHeight
      if (!val && this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "none"
        }, 300)
      } else {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "0px"
        }, 50)
      }
      if(val && this.open) {
        this.maxHeight = `${scrollHeight}px`
        setTimeout(() => {
          this.maxHeight = "0px"
        }, 250)
      }
    },
  },
}
</script>

<style lang="scss">
.iconsmaterial{
  color: #797474 !important;
}
@import "@/assets/scss/vuesax/components/vxSidebarGroup.scss"
</style>
