<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon"
          @click.stop.prevent="showSidebar"></feather-icon>
        <template v-if="breakpoint != 'md'">
          <!-- STARRED PAGES - FIRST 10 -->
          <ul class="vx-navbar__starred-pages">
            <draggable v-model="starredPagesLimited" :group="{ name: 'pinList' }" class="flex cursor-move">
              <li class="starred-page" v-for="page in starredPagesLimited" :key="page.url">
                <vx-tooltip :text="page.label" position="bottom" delay=".3s">
                  <feather-icon svgClasses="h-6 w-6" class="p-2 cursor-pointer" :icon="page.labelIcon"
                    @click="$router.push(page.url)"></feather-icon>
                </vx-tooltip>
              </li>
            </draggable>
          </ul>
          <!-- STARRED PAGES MORE -->
          <div class="vx-navbar__starred-pages--more-dropdown" v-if="starredPagesMore.length">
            <vs-dropdown vs-custom-content vs-trigger-click>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="cursor-pointer p-2"></feather-icon>
              <vs-dropdown-menu>
                <ul class="vx-navbar__starred-pages-more--list">
                  <draggable v-model="starredPagesMore" :group="{ name: 'pinList' }" class="cursor-move">
                    <li class="starred-page--more flex items-center cursor-pointer" v-for="page in starredPagesMore"
                      :key="page.url" @click="$router.push(page.url)">
                      <feather-icon svgClasses="h-5 w-5" class="ml-2 mr-1" :icon="page.labelIcon"></feather-icon>
                      <span class="px-2 pt-2 pb-1">{{ page.label }}</span>
                    </li>
                  </draggable>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="bookmark-container">
            <feather-icon icon="SearchIcon"
              :svgClasses="['stoke-current', { 'text-white': navbarColor != '#fff' }]"
              class="cursor-pointer p-2" @click.stop="showBookmarkPagesDropdown = !showBookmarkPagesDropdown" />
            <div v-click-outside="outside" class="absolute bookmark-list w-1/3 xl:w-1/4 mt-4"
              v-if="showBookmarkPagesDropdown">
              <vx-auto-suggest :autoFocus="true" :data="navbarSearchAndPinList" @selected="selected"
                @actionClicked="actionClicked" inputClassses="w-full" show-action show-pinned background-overlay />
            </div>
          </div>
          <small class="bold">{{ customerPlanName.replace("Plano", "") }}</small>
        </template>
        <vs-spacer></vs-spacer>
        <!-- USER META -->
        <div class="the-navbar__user-meta flex items-center">
          <div class="text-right leading-tight sm:block">
            <p class="font-semibold hidden-mobile">{{ user_displayName }}</p>
             <template v-if="!isProjectPlan">
              <small v-if="loadSaldoState === 'success'">
                {{ type }}
              </small>
              <small v-if="loadSaldoState === 'error'">
                Saldo indisponível.
              </small>
              <small v-if="loadSaldoState === 'loading'">
                Carregando saldo...
              </small>
            </template>
          </div>
          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="hidden-mobile">
              <div class="con-img ml-3">
                <img v-if="activeUserImg.endsWith('png')" key="onlineImg"
                  :src="require(`@/assets/images/portrait/small/${activeUserImg}`)" alt="user-img" width="40" height="40"
                  class="rounded-full shadow-md cursor-pointer block" />
                <img ref="imgProfile" v-else key="localImg" :src="activeUserImg" alt="user-img" width="40" height="40"
                  class="rounded-full shadow-md cursor-pointer block" />
              </div>
            </div>
            <div class="con-img ml-3 hidden-web">
              <b-icon icon="person-lines-fill"></b-icon>
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/profile')">
                  <feather-icon class="icon hover:text-white" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Perfil</span>
                </li>
                <vs-divider class="m-1"></vs-divider>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/logout')">
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">Sair</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import draggable from "vuedraggable"
import { computed, defineComponent } from "vue"

import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue"

export default defineComponent({
  name: "the-navbar",
  components: {
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable,
  },
  mixins: [mixins],
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  data() {
    return {
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      customerPlanName: "",
      isProjectPlan: false,
      loadSaldoState: "initial",
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: this.randomDate({ sec: 10 }),
          category: "primary",
        },
        {
          index: 1,
          title: "New Order Recieved",
          msg: "You got new order of goods.",
          icon: "PackageIcon",
          time: this.randomDate({ sec: 40 }),
          category: "success",
        },
        {
          index: 2,
          title: "Server Limit Reached!",
          msg: "Server have 99% CPU usage.",
          icon: "AlertOctagonIcon",
          time: this.randomDate({ min: 1 }),
          category: "danger",
        },
        {
          index: 3,
          title: "New Mail From Peter",
          msg: "Cake sesame snaps cupcake",
          icon: "MailIcon",
          time: this.randomDate({ min: 6 }),
          category: "primary",
        },
        {
          index: 4,
          title: "Bruce's Party",
          msg: "Chocolate cake oat cake tiramisu",
          icon: "CalendarIcon",
          time: this.randomDate({ hr: 2 }),
          category: "warning",
        },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
      saldo: 0,
      poolBalance: 0,
    };
  },
  setup () {
    const store = useStore()
    return {
      breakpoint: computed(() => store.state.breakpoint),
      navbarSearchAndPinList: computed(() => store.state.navbarSearchAndPinList),
      sidebarWidth: computed(() => store.state.sidebarWidth),
      starredPages: computed(() => store.state.starredPages),
      updateStarredPage: (data) => store.dispatch("updateStarredPage", data),
      updateStarredPagesLimited: (data) => store.dispatch("arrangeStarredPagesLimited", data),
      updateStarredPagesMore: (data) => store.dispatch("arrangeStarredPagesMore", data),
    }
  },
  mounted() {
    const customerPlan = this.$session.get("customerPlan")
    if(customerPlan){
      this.customerPlanName = customerPlan.name ? `Plano ${customerPlan.name}` : ""
    }

    this.isProjectPlan = this.$session.get("isProject")
      ? this.$session.get("isProject")
      : false

    this.getAvaliableBalance()
  },
  computed: {
    classObj() {
      const types = ["default", "reduced"]

      return types.includes(this.sidebarWidth)
        ? `navbar-${this.sidebarWidth}`
        : "navbar-full"
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10)
      },
      set(list) {
        this.updateStarredPagesLimited(list)
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10)
      },
      set(list) {
        this.updateStarredPagesMore(list)
      },
    },
    user_displayName() {
      return this.$session.get("name")
    },
    type() {
      return this.saldo
        ? `Saldo Conta Pool + Carteira: ${this.maskMoney(this.saldo + this.poolBalance)}`
        : "Saldo: R$ 0,00"
    },

    activeUserImg() {
      return this.$session.get("photo")
        ? `data:image/png;base64,${this.$session.get("photo")}`
        : this.$store.state.appActiveUser.img
    },
  },
  methods: {
    getAvaliableBalance() {
      this.loadSaldoState = "loading";
      this.axios.get(
        `${this.$store.state.filooServer}wallet/overview`,
        { headers: { Authorization: this.$session.get("bearer")}}
      )
        .then(({ data }) => {
          this.saldo = data.avaliableBalance
          this.poolBalance = data.bonusBalance || 0
          this.$session.set("saldoPoolAccount", this.poolBalance)
          this.$session.set("saldo", this.saldo)
          this.loadSaldoState = "success";
        });
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      this.updateStarredPage({ index: item.index, val: !item.highlightAction })
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let timeDiff = (new Date() - new Date(startTime)) / 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago"
      } 
      if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago"
      } 
      if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago"
      } 
      if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago"
      } 
      if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now")
      }

      return "Just Now"
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },
    randomDate({ hr, min, sec }) {
      const date = new Date()

      if (hr) {
        date.setHours(date.getHours() - hr)
      }
      if (min) {
        date.setMinutes(date.getMinutes() - min)
      }
      if (sec) {
        date.setSeconds(date.getSeconds() - sec)
      }

      return date
    },
  },
  watch: {
    $route() {
      var vm = this;
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
      vm.getAvaliableBalance();
    },
  },
});
</script>
<style lang="scss" >
.button-plan-update {
  transform: translateX(27vw);

  .vs-button--text{
    font-size: 1.3em;
  }
}

.flex.py-2.px-4:hover {
  color: white !important;
}

.bold {
  font-weight: 600;
}

#pool-balance-navbar {
  margin-left: 3%;
}

.text-right.leading-tight.hidden {
  min-width: 300px !important;
}

.hidden-web {
  font-size: 2rem;
  color: rgba(var(--vs-primary), 1);
}

@media (max-width: 500px) {
  .hidden-mobile {
    display: none;
  }
}

@media (min-width: 500px) {
  .hidden-web {
    display: none;
  }
}

</style>
